import React from 'react';
import { breakpoints, radius, spacing, themeLight } from '@naf/theme';
import styled, { css } from 'styled-components';
import { Text, TextVariant } from '@naf/text';
import { Label } from '@naf/label';
import ResponsiveImage from '../../../../image/ResponsiveImage';
import { ColorVariant } from '../../../../../../../types/featuredBenefitBlockType';
import { TrialMembershipNavigationCardType } from '../../../../../../../types/trialMembershipNavigationCardContainerBlockType';
import { TrialMembershipNavigationCardPoint } from './TrialMembershipNavigationCardPoint';
import { LinkButton } from './LinkButton';
import { getLabelVariant } from '../../../../../lib/getLabelVariant';

const Header = styled.div`
  grid-area: header;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${spacing.space16};

  h2 {
    margin: 0;
  }
`;

const ImageWrapper = styled.div`
  grid-area: image;
  display: flex;
`;

const ImageCanvas = styled.div`
  width: 100%;
  padding-top: ${(9 / 16) * 100}%;
  position: relative;

  img {
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: ${radius.s};
  }
`;

const Content = styled.div`
  grid-area: content;
  place-self: end;
  display: flex;
  flex-direction: column;
  gap: ${spacing.space24};

  @media (max-width: ${breakpoints.m}) {
    place-self: start;
    width: 100%;
  }

  justify-self: start;
`;

const PointWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: ${spacing.space8};
  column-gap: ${spacing.space16};
`;

function getBackground(variant: ColorVariant) {
  return css`
    ${variant === 'signature' &&
    css`
      background: ${({ theme }) => (theme.background ? theme.background.important : themeLight.background.important)};
    `}
    ${variant === 'secondary' &&
    css`
      background: ${({ theme }) => (theme.background ? theme.background.brand : themeLight.background.brand)};
    `}
  ${variant === 'outline' &&
    css`
      background: ${({ theme }) => (theme.background ? theme.background.default : themeLight.background.default)};
    `}
  ${variant === 'spruce' &&
    css`
      background: ${({ theme }) =>
        theme.background ? theme.background.brandInverted : themeLight.background.brandInverted};
    `}
  `;
}

const Wrapper = styled.div<{ $variant?: ColorVariant }>`
  ${(props) =>
    props.$variant
      ? getBackground(props.$variant)
      : css`
          background: ${({ theme }) => (theme.background ? theme.background.default : themeLight.background.default)};
        `}
  padding: ${spacing.space48};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-gap: ${spacing.space24};
  grid-template-areas:
    'header image'
    'content image';

  @media (max-width: ${breakpoints.m}) {
    grid-gap: ${spacing.space24} 0;
    padding: ${spacing.space24};
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      'header'
      'image'
      'content';
  }
`;

export const TrialMembershipNavigationCard = ({ card }: { card: TrialMembershipNavigationCardType }) => {
  const { colorVariant, title, label, image, pointList, buttonWithLink } = card;
  const labelVariant = getLabelVariant(label.colorVariant);
  return (
    <Wrapper $variant={colorVariant}>
      <Header>
        <Text tag="h2" variant={TextVariant.Header2}>
          {title}
        </Text>
        {label ? <Label variant={labelVariant} text={label.title} /> : null}
      </Header>
      <ImageWrapper>
        <ImageCanvas>
          {image ? <ResponsiveImage imageId={image.publicId} altText={image.altText || image.caption || ''} /> : null}
        </ImageCanvas>
      </ImageWrapper>
      <Content>
        <PointWrapper>
          {pointList.map((point) => (
            <TrialMembershipNavigationCardPoint key={point.title} title={point.title} icon={point.icon} />
          ))}
        </PointWrapper>
        {buttonWithLink ? (
          <div>
            <LinkButton colorVariant={buttonWithLink.colorVariant} link={buttonWithLink.link} />
          </div>
        ) : null}
      </Content>
    </Wrapper>
  );
};
