import React from 'react';
import { Button } from '@naf/button';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LinkType } from '../../../../../../../types/LinkType';
import { ColorVariant } from '../../../../../../../types/featuredBenefitBlockType';
import { useDocumentUrlWithNode } from '../../../../../hooks/useDocumentUrl';

export interface LinkButtonProps {
  colorVariant?: ColorVariant;
  link: LinkType;
}

const StyledButton = styled(Button)`
  cursor: pointer;
`;

export const LinkButton = (props: LinkButtonProps) => {
  const { colorVariant, link } = props;
  const { targetType, externalTarget, internalReference, title } = link;
  const internalItemUrl = useDocumentUrlWithNode(internalReference);

  if (targetType === 'externalTarget' && externalTarget.href) {
    return (
      <Button
        type="button"
        href={externalTarget.href}
        target={externalTarget.blank ? '_blank' : '_self'}
        variant={colorVariant}
      >
        {title}
      </Button>
    );
  }

  if (targetType === 'internalReference' && !!internalReference && !!internalItemUrl) {
    return (
      <Link to={internalItemUrl}>
        <StyledButton type="button" isNotClickable variant={colorVariant}>
          {title}
        </StyledButton>
      </Link>
    );
  }

  return null;
};
