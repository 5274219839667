import React from 'react';
import styled from 'styled-components';
import { Text, TextVariant } from '@naf/text';
import { NafIconName, NafIcon, NafIconSize } from '@naf/naf-icons';
import { spacing } from '@naf/theme';
import { AddTask, VerifiedUser } from '@styled-icons/material-outlined';
import {
  TrialMembershipNavigationCardPointIconType,
  TrialMembershipNavigationCardPointType,
} from '../../../../../../../types/trialMembershipNavigationCardContainerBlockType';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.space4};
  max-width: 12rem;
  text-wrap: balance;
`;

const StyledText = styled(Text)`
  margin: 0;
`;

const Icon = ({ icon }: { icon: TrialMembershipNavigationCardPointIconType }) => {
  const { library, materialIcon, materialOutlinedIcon, nafIconsIcon } = icon;

  if (library === 'material' && !!materialIcon) {
    switch (materialIcon) {
      default:
        return null;
    }
  }

  if (library === 'material-outlined' && !!materialOutlinedIcon) {
    switch (materialOutlinedIcon) {
      case 'AddTask':
        return <AddTask size={24} />;
      case 'VerifiedUser':
        return <VerifiedUser size={24} />;
      default:
        return null;
    }
  }

  if (library === 'naf-icons' && !!nafIconsIcon) {
    return <NafIcon name={nafIconsIcon} size={NafIconSize.Standard} />;
  }

  return <NafIcon name={NafIconName.NafLogo} size={NafIconSize.Standard} />;
};

export const TrialMembershipNavigationCardPoint = (props: TrialMembershipNavigationCardPointType) => {
  const { title, icon } = props;

  return (
    <Wrapper>
      {icon ? <Icon icon={icon} /> : null}
      <StyledText tag="p" variant={TextVariant.BodyText}>
        {title}
      </StyledText>
    </Wrapper>
  );
};
