import React from 'react';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { TrialMembershipNavigationCardContainerBlockType } from '../../../../../../../types/trialMembershipNavigationCardContainerBlockType';
import { TrialMembershipNavigationCard } from './TrialMembershipNavigationCard';

const StyledTitle = styled(Text)`
  margin-bottom: 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.space8};
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.space24};

  @media (max-width: ${breakpoints.m}) {
    position: relative;
    right: 50%;
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    width: 100vw;
  }
`;

export const TrialMembershipNavigationCardContainerBlock = ({
  block,
}: {
  block: TrialMembershipNavigationCardContainerBlockType;
}) => {
  const { cards, title } = block;

  return (
    <Wrapper>
      <StyledTitle tag="h3" variant={TextVariant.Header3}>
        {title}
      </StyledTitle>
      <CardWrapper>
        {cards.map((card) => (
          <TrialMembershipNavigationCard key={card.title} card={card} />
        ))}
      </CardWrapper>
    </Wrapper>
  );
};
